import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
  data as dataResponse,
} from 'react-router';
import { Form, Link, useFetcher, useNavigation, useSearchParams } from 'react-router';
import React from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { AppleIcon } from '~/components/AppleIcon';
import { GoogleIcon } from '~/components/GoogleIcon';
import { Visibility } from '~/components/icons/Visibility';
import { VisibilityOff } from '~/components/icons/VisibilityOff';
import { Button, buttonVariants } from '~/components/ui/Button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/Form';
import { Image } from '~/components/ui/Image';
import { Input } from '~/components/ui/Input';
import { HeadingL, textVariants } from '~/components/ui/Typography';
import {
  type EmailValidity,
  type IsEmailValidQueryVariables,
  isEmailValidQuery,
} from '~/generated/server';
import { useTranslation } from '~/i18n';
import { authenticator, commitViewerSession, redirectOnAuthenticated } from '~/utils/auth.server';
import { TypedFormData } from '~/utils/form-data';
import { mergeMeta } from '~/utils/merge-meta';
import { getMeta } from '~/utils/meta';
import { namedAction } from '~/utils/named-action.server';
import { getReturnToCookie, returnToCookie } from '~/utils/session.server';

export const handle = {
  i18n: 'login',
};

export const meta: MetaFunction = mergeMeta(() =>
  getMeta([
    {
      title: 'Se connecter au Club Colette - Club Colette',
    },
    {
      name: 'description',
      content:
        'Inscrivez-vous gratuitement et sans engagement en quelques clics par e-mail | Faites des rencontres grâce au Club Colette 💛',
    },
  ]),
);

export async function action(ctx: ActionFunctionArgs) {
  return await namedAction(ctx.request, {
    async login() {
      const viewer = await authenticator.authenticate('form', ctx.request.clone());

      const path = await getReturnToCookie(ctx.request);

      return new Response(null, {
        status: 302,
        headers: {
          Location: path,
          'Set-Cookie': await commitViewerSession(ctx.request, viewer),
        },
      });
    },

    async validateEmail() {
      const fd = await new TypedFormData<IsEmailValidQueryVariables>().init(ctx.request);

      const response = await isEmailValidQuery(ctx, { email: fd.get('email') });

      return {
        emailValidity: response.isEmailValid,
        email: fd.get('email'),
      };
    },
  });
}

export async function loader(ctx: LoaderFunctionArgs) {
  const next = new URL(ctx.request.url).searchParams.get('next');

  await redirectOnAuthenticated(ctx, next || '/');

  if (next) {
    return dataResponse(null, {
      headers: {
        'Set-Cookie': await returnToCookie.serialize({
          next,
        }),
      },
    });
  }

  return {};
}

export default function Login() {
  const { t } = useTranslation('login');
  const navigation = useNavigation();
  const emailChecker = useFetcher<{
    email: string;
    emailValidity: EmailValidity;
  }>();
  const [sp] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);

  const isSubmitting = navigation.state === 'submitting';
  const isEmailUnknown =
    emailChecker.data?.emailValidity != null &&
    emailChecker.data?.emailValidity !== 'ALREADY_TAKEN';

  const debounced = useDebounceCallback((email?: string) => {
    if (email) {
      emailChecker.submit(
        {
          email,
        },
        { method: 'POST', action: '?index&/validateEmail' },
      );
    }
  }, 500);

  return (
    <div className="grid h-screen px-4 lg:grid-cols-2 lg:px-0">
      <div className="mx-auto grid w-full max-w-[400px] place-items-center">
        <div className="w-full">
          <div className="mb-10 space-y-4">
            <HeadingL>{t('login')}</HeadingL>
            <p
              className={textVariants({
                size: 'm',
                class: 'font-medium text-subdued-foreground',
              })}
            >
              {t('login-description')}
            </p>
          </div>

          <Form
            className="mb-4 w-full flex-1 space-y-3"
            method="POST"
            action={'?index&/login'}
            replace
          >
            <FormItem>
              <FormField>
                <FormLabel>{t('email')}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    name="email"
                    placeholder="colette@mail.com"
                    defaultValue={sp.get('email') || undefined}
                    onChange={(e) => e.currentTarget.validity.valid && debounced(e.target.value)}
                    required
                  />
                </FormControl>
              </FormField>
              <FormMessage />
            </FormItem>

            {isEmailUnknown ? (
              <div className="space-y-3 rounded-2xl border p-3">
                <p
                  className={textVariants({
                    size: 's',
                    class: 'font-medium text-error',
                  })}
                >
                  {t('email-error')}
                </p>

                <Link
                  to={'/signup/email?email=' + encodeURIComponent(String(emailChecker.data?.email))}
                  className={buttonVariants({
                    variant: 'tertiary',
                    class: 'w-full',
                  })}
                  prefetch="intent"
                >
                  {t('new-account')}
                </Link>
              </div>
            ) : null}

            <FormItem>
              <FormField className="relative">
                <FormLabel>{t('password')}</FormLabel>
                <FormControl>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="············"
                    required
                  />
                </FormControl>

                <button
                  onClick={() => setShowPassword((prev) => !prev)}
                  type="button"
                  className="absolute inset-y-0 right-4 grid h-full place-content-center rounded-r-xl font-bold text-subdued"
                >
                  {showPassword ? (
                    <Visibility className="size-5" />
                  ) : (
                    <VisibilityOff className="size-5" />
                  )}
                </button>
              </FormField>
              <FormMessage />
            </FormItem>

            <Button
              className="w-full"
              type="submit"
              loading={isSubmitting && navigation.formAction.endsWith('login')}
            >
              {t('login-button')}
            </Button>

            <Link
              prefetch="intent"
              to="/forgot-password"
              className={buttonVariants({
                class: 'w-full',
                variant: 'tertiary',
              })}
            >
              {t('forgot-password')}
            </Link>

            <Link
              prefetch="intent"
              to="/login/magic-link"
              className={buttonVariants({ class: 'w-full', variant: 'link' })}
            >
              {t('magic-link')}
            </Link>
          </Form>

          <div className="relative mb-4 flex w-full justify-center before:z-0 after:absolute after:inset-x-0 after:top-1.5 after:h-0.5 after:w-full after:bg-border after:content-['']">
            <span
              className={textVariants({
                size: 's',
                class: 'z-10 block bg-background px-4 font-bold text-subdued',
              })}
            >
              {t('or')}
            </span>
          </div>

          <Form method="post" action="/auth/google" className="mb-4 flex w-full justify-center">
            <Button
              className="w-full"
              variant="tertiary"
              loading={isSubmitting && navigation.formAction.endsWith('/auth/google')}
            >
              <GoogleIcon className="size-4" />
              {t('continue-with-google')}
            </Button>
          </Form>
          <Form method="post" action="/auth/apple" className="mb-6 flex w-full justify-center">
            <Button
              className="w-full"
              variant="tertiary"
              loading={isSubmitting && navigation.formAction.endsWith('/auth/apple')}
              disabled
            >
              <AppleIcon className="size-6" />

              {t('continue-with-apple')}
            </Button>
          </Form>

          <div className="relative mb-4 flex w-full justify-center before:z-0 after:absolute after:inset-x-0 after:top-1.5 after:h-0.5 after:w-full after:bg-border after:content-['']">
            <span
              className={textVariants({
                size: 's',
                class: 'z-10  block bg-background px-4 font-bold text-subdued-foreground',
              })}
            >
              {t('or')}
            </span>
          </div>

          <span
            className={textVariants({
              size: 'm',
              class: 'mb-4 block font-bold',
            })}
          >
            {t('dont-have-account')}
          </span>
          <Link
            className={buttonVariants({ variant: 'tertiary', class: 'w-full' })}
            to="/signup"
            prefetch="intent"
          >
            {t('signup')}
          </Link>
        </div>
      </div>

      <div className="hidden lg:block">
        <Image src="/images/login-cover.jpeg" className="h-full object-cover object-right-top" />
      </div>
    </div>
  );
}
